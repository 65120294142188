<template>
    <div class="mianbox">
        <div class="searchbox">
            <el-form ref="form" :model="form" label-width="80px" class="searchbox_left">
                <div class="leftbox">
                    <!-- <div class="left_one">
                        <el-select 
                            v-model="form.name" 
                            filterable 
                            placeholder="请输入或选择部门名称查询"
                            size="small"
                            style="width:100%">
                            <i slot="prefix" class="el-input__icon el-icon-search"></i>
                            <el-option
                                v-for="item in tableData_s"
                                :key="item.Name"
                                :label="item.Name==''?'全部':item.Name"
                                :value="item.Name">
                            </el-option>
                        </el-select>
                    </div>
                    
                    <div class="left_three">
                        <el-button type="primary" size="small" plain  @click="onSubmit">查询</el-button>
                    </div>  -->
                </div>
            </el-form>
            <div class="rightbox">
                <el-button type="primary" size="small" @click="newbuild()">新建部门</el-button>
            </div> 
        </div>
        <!--新建部门弹窗-->
        <el-dialog title="创建部门" :visible.sync="dialogFormVisible" width="30%">
            <el-form ref="newform" :model="newform" label-width="100px" :rules="rules">
                <el-form-item label="部门名称" prop="name">
                    <el-input v-model="newform.name" placeholder="请填写部门名称"></el-input>
                </el-form-item>
                
                <el-form-item label="上级部门" label-width="100px" prop="Pid">
                    <el-cascader
                        placeholder="请选择上级部门"
                        :options="options"
                        v-model="newform.Pid"
                        clearable
                        filterable
                        style="width:100%"
                        :props="{ checkStrictly: true }">
                    </el-cascader>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel()">取 消</el-button>
                <el-button type="primary" @click="determine('newform')">确 定</el-button>
            </div>
        </el-dialog>
        <!--编辑弹窗-->
        <el-dialog title="编辑部门" :visible.sync="dialogFormVisible1" width="30%">
            <el-form ref="oldform" :model="oldform" label-width="100px" :rules="rules">
                <el-form-item label="部门名称" prop="name">
                    <el-input v-model="oldform.name" placeholder="请填写部门名称"></el-input>
                </el-form-item>
                
                <el-form-item label="上级部门" label-width="100px" prop="Pid">
                    <el-cascader
                        placeholder="请选择上级部门"
                        :options="options"
                        v-model="oldform.Pid"
                        clearable
                        filterable
                        style="width:100%"
                        :props="{ checkStrictly: true }">
                    </el-cascader>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel1()">取 消</el-button>
                <el-button type="primary" @click="determine1('oldform')">确 定</el-button>
            </div>
        </el-dialog>

        <div class="Tablebox">
            <el-table
            :data="tableData"
            border
            :style="{width: '100%','margin-bottom':'2rem'}"
            :header-cell-style="{background:'#f5f7fa'}"
            default-expand-all
            row-key="value"
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                <el-table-column v-for="(item, index) in tableList" :key="index" :prop="item.prop" :label="item.label"></el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="240">
                    <template slot-scope="scope">
                        <el-button @click="handleClick(scope.row)" type="text" size="small">编辑</el-button>
                        <el-button type="text" size="small" @click="handleDelete(scope.$index, scope.row)" slot="reference">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            
        </div>
    </div>
</template>

<script>
    import { instance } from "../../../apis/apis.js";
    export default {
        data(){
            return{
                //经办部门列表
                departmentlist:[],
                //弹窗
                options: [],
                rules: {
                    name: [
                        { required: true, message: '请输入部门名称', trigger: 'blur' },
                    ],
                    Pid: [
                        { required: true, message: '请选择上级部门', trigger: 'blur' },
                    ],
                },
                dialogFormVisible: false,
                dialogFormVisible1: false,
                newform: {
                    name: '',
                    Pid:'',
                    IsDeleted:false
                },
                oldform: {
                    name: '',
                    Pid:'',
                    IsDeleted:false,
                    value:''
                },
                 //列表展示的值
                tableList:[
                    // {
                    //     prop:'value',
                    //     label:'部门编号'
                    // },
                    {
                        prop:'label',
                        label:'部门名称'
                    },
                    // {
                    //     prop:'Leader',
                    //     label:'部门负责人'
                    // }
                ],
                //搜索关键词
                form: {
                    name: '',
                },
                //表单数据
                 tableData: [],
                //分页
                paging:{
                    currentPage:1,//当前加载页
                    size:10,//每页显示条目个数
                    total:400,//总条目数
                }
            }
        },
        created(){
            this.getdepartmentlist()
            //this.getdepartmentlist_s()
        },
        methods: {
            //获取部门列表
            getdepartmentlist(){
                instance.get('/Department/GetDepartmentTree').then(res=>{
                    if(res.data.success){
                        //console.log(res.data.response.children)
                        this.tableData=res.data.response.children
                        this.options=res.data.response.children
                        this.options.unshift({
                            Pid: 0,
                            label:'最高级',
                            value:0
                        });
                    }
                });
            },
           
            //新建部门
            newbuild(){
                this.dialogFormVisible=true
            },
            cancel(){
                this.dialogFormVisible=false
            },
            cancel1(){
                this.dialogFormVisible1=false
            },
            determine(formName){
                //console.log(this.newform.Pid)
                let index = this.newform.Pid.length - 1 
                this.newform.Pid=this.newform.Pid[index]
                // console.log(this.newform)
                this.$refs[formName].validate((valid) => {
                if (valid) {
                    instance.post('/Department/Post',
                        {
                            Name: this.newform.name,
                            IsDeleted:this.newform.IsDeleted,
                            Pid:this.newform.Pid
                        }).then(res=>{
                        console.log(res)
                    });
                    // this.$message({
                    //     message: '创建成功',
                    //     type: 'success'
                    // });
                } else {
                    this.$message.error('请正确填写信息');
                    }
                });
            },
            determine1(formName){
                console.log(this.oldform)
            },
            //查询按钮
            onSubmit() {
                this.getdepartmentlist()
            },
            //编辑
            handleClick(row) {
                console.log(row);
                this.dialogFormVisible1=true
                this.oldform.name=row.label

            },
            //删除
            handleDelete(index, row){
                this.$confirm('您确定删除编号为：'+row.number+'的文件吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                .then(() => {
                    this.tableData.splice(index, 1);
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                })
                .catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消删除'
                        });          
                });
                console.log(index, row);
            }, 
        } 
    }
</script>

<style lang="less" scoped>
    .mianbox{
        padding: 1.5rem;
        .searchbox{
            padding: 2rem;
            background-color: #ffffff;
            box-shadow: 0 0 0.6rem #e0e0e0;
            border-radius:0.5rem ;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .searchbox_left{
                .leftbox{
                    display: flex;
                    align-items: center;
                    .left_one{
                        width: 26.8rem;
                    }
                    .left_two{
                        width: 28rem;
                        margin-left:2rem ;
                        .el-form-item{
                            margin-bottom: 0 !important;
                        }
                    }
                    .left_three{
                        margin-left:2rem ;
                    }
                }
            }
            // .rightbox{

            // }
        }
        .Tablebox{
            padding: 2rem;
            background-color: #ffffff;
            box-shadow: 0 0 0.6rem #e0e0e0;
            border-radius:0.5rem ;
            margin-top: 1.5rem;
        }
    }
</style>